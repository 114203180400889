div {
    box-sizing: border-box;
    vertical-align: top;
}

html{
    font-family: Arial, Helvetica, sans-serif;
}

body{
    margin: 0;
    padding: 0;
}

.login-page {
    background-color: #FFFFCC;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    border-radius: 10px;
    width: 400px;
    background-color: rgb(224, 216, 216);
    /* padding: 10px; */
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}


.song-item{
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    height: 400px;
    border: solid gray 1px;
    background-color: rgb(215, 215, 215);
    display: inline-flex;
    justify-content: space-between;
}

.ptr-item{
    margin: 5px;
    padding: 20px;
    border-radius: 5px;
    height: 900px;
    width: 1460px;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border: solid gray 1px;
    background-color: rgb(215, 215, 215);
    display: inline-flex;
    justify-content: space-between;
}
.about {
    font-family: Arial;
    color: black;
}
.chatpage{
    display: inline-flex;
    width: 500px;
    font-size: larger;
    gap: 10px;
    margin: 10px;
    border: solid gray 1px;
    padding: 10px;   
}

.book-card{
    display: inline-flex;
    width: 500px;
    gap: 10px;
    margin: 10px;
    border: solid gray 1px;
    padding: 10px;
}
.book-category{
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    background-color: gray;
    color: white;
    margin-right: 5px;
}


.app-header{
    background-color: red;
    font-weight: bold;
    color: white;
    display: flex;
    text-decoration: none;
    height: 40px;
    align-items: center;
    gap: 30px;
}

.app-nav-bar{
    text-decoration: none;
}

.container{

    height: 600px;
    background-image: url("./pages/Index/webimages/ctdelarea.png");
    background-repeat: no-repeat;
    background-size: 1200px;
    background-position: right;
    padding: 15px;
    margin: 10px;
}

.app-link{
    text-decoration: none;
    color: white;
    margin: 10px;
}

.App {
    background-color: #FFFFCC;
}

.footdesc {
    margin-right: 30px;
    font: "Arial";
    color: black;
    font-weight: bold;
    text-align: center;
}

