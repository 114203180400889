.dropdown-menu {
  /* background: red; */
  width: 170px;
  position: absolute;
  left: 110px;
  top: 424px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: red; 
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
